const { defaultLanguage } = require('../../prismic-configuration');

exports.linkResolver = doc => {
  const isDefaultLanguage = doc.lang === defaultLanguage; // commented out to avoid rendering other languages
  switch (doc.type) {
    case 'forum_homepage': {
      return isDefaultLanguage ? `/${doc.uid}` : `/${doc.lang}/${doc.uid}`;
    }
    case 'forum_collection_page': {
      return isDefaultLanguage
        ? `/euraceconnect/${doc.uid}`
        : `/${doc.lang}/euraceconnect/${doc.uid}`;
    }
    case 'forum_registration_page': {
      return isDefaultLanguage
        ? `/euraceconnect/${doc.uid}`
        : `/${doc.lang}/euraceconnect/${doc.uid}`;
    }
    case 'homepage': {
      return isDefaultLanguage ? '/' : `/${doc.lang}`;
    }
    case 'edit_mcm_forms': {
      return isDefaultLanguage ? `/${doc.uid}` : `/${doc.lang}/${doc.uid}`;
    }
    case 'fullportfoliopage': {
      return isDefaultLanguage
        ? `/enter-educators/${doc.uid}`
        : `/${doc.lang}/enter-educators/${doc.uid}`;
    }
    case 'professional_portfolio_card': {
      return isDefaultLanguage
        ? `/enter-educators/${doc.uid}`
        : `/${doc.lang}/enter-educators/${doc.uid}`;
    }
    case 'mcm_review_form': {
      return isDefaultLanguage
        ? `/enter-educators/${doc.uid}`
        : `/${doc.lang}/enter-educators/${doc.uid}`;
    }
    case 'final_step_approval_proccess': {
      return isDefaultLanguage
        ? `/enter-educators/${doc.uid}`
        : `/${doc.lang}/enter-educators/${doc.uid}`;
    }
    case 'collection_page': {
      return isDefaultLanguage ? `/${doc.uid}` : `/${doc.lang}/${doc.uid}`;
    }
    case 'forum_page': {
      return isDefaultLanguage
        ? `/euraceconnect/${doc.uid}`
        : `/euraceconnect/${doc.lang}/${doc.uid}`;
    }
    case 'page': {
      return isDefaultLanguage ? `/${doc.uid}` : `/${doc.lang}/${doc.uid}`;
    }
    case 'news': {
      return isDefaultLanguage ? `/all-news/${doc.uid}` : `/${doc.lang}/all-news/${doc.uid}`;
    }

    case 'remember': {
      return isDefaultLanguage
        ? `/enter-registered-educators/${doc.uid}`
        : `/${doc.lang}/enter-registered-educators/${doc.uid}`;
    }
    case 'pdp_member': {
      return isDefaultLanguage
        ? `/enter-registered-professional-development-programs/${doc.uid}`
        : `/${doc.lang}/enter-registered-professional-development-programs/${doc.uid}`;
    }
    case 'login_and_sign_up_page': {
      return isDefaultLanguage ? `/${doc.uid}` : `/${doc.lang}/${doc.uid}`;
    }
    case 'enter_account_page': {
      return isDefaultLanguage ? `/${doc.uid}` : `/${doc.lang}/${doc.uid}`;
    }
    default:
      return '/no-page';
  }
};
